<template>
  <div
    v-if="!loading"
    id="user-view"
  >
    <app-breadcrumb
      title="Detail Pelanggan"
      hide-breadcrumbs
      :hide-title="$vuetify.breakpoint.smAndDown"
    >
      <template #action>
        <v-btn
          v-show="$can('update', 'Customer')"
          outlined
          small
          color="warning"
          @click="$refs.customerForm.update(detail.id)"
        >
          Ubah
        </v-btn>
        <v-btn
          v-show="$can('del', 'Customer')"
          outlined
          small
          color="error"
          class="ms-2"
          @click="deleteCustomerFunc(detail.id)"
        >
          Hapus
        </v-btn>
      </template>
    </app-breadcrumb>
    <v-row>
      <v-col
        cols="12"
        md="4"
        lg="3"
      >
        <v-card>
          <div class="pa-4 pb-1 d-flex">
            <v-avatar
              size="44"
              class="v-avatar-light-bg mr-2"
            >
              {{ avatarText(detail.name) }}
            </v-avatar>
            <div>
              <span class="text-subtitle-2 font-weight-bold d-block primary--text">{{ detail.name }}</span>
              <span class="text-caption font-medium">{{ detail.phone_number }}</span>
            </div>
          </div>
          <div class="px-4 py-2">
            <div
              v-if="detail.email"
              class="d-flex align-center my-2"
            >
              <v-icon
                size="30px"
                class="mr-3 mt-1"
              >
                {{ icons.mdiEmail }}
              </v-icon>
              <div class="d-flex flex-column text-truncate">
                <span class="text-caption text--disabled">Email</span>
                <span class="text-subtitle-2">{{ detail.email }}</span>
              </div>
            </div>
            <div
              v-if="detail.address"
              class="d-flex align-center mb-2"
            >
              <v-icon
                size="30px"
                class="mr-3"
              >
                {{ icons.mdiMap }}
              </v-icon>
              <div class="d-flex flex-column">
                <span class="text-caption text--disabled">Alamat</span>
                <span class="text-subtitle-2">{{ detail.address }}</span>
              </div>
            </div>
            <div
              v-if="detail.job_title"
              class="d-flex align-center mb-2"
            >
              <v-icon
                size="30px"
                class="mr-3"
              >
                {{ icons.mdiBriefcase }}
              </v-icon>
              <div class="d-flex flex-column">
                <span class="text-caption text--disabled">Pekerjaan</span>
                <span class="text-subtitle-2">{{ detail.job_title }}</span>
              </div>
            </div>
            <div
              v-if="detail.instagram"
              class="d-flex align-center mb-2"
            >
              <v-icon
                size="30px"
                class="mr-3"
              >
                {{ icons.mdiInstagram }}
              </v-icon>
              <div class="d-flex flex-column">
                <span class="text-caption text--disabled">Instagram</span>
                <span class="text-subtitle-2">{{ detail.instagram }}</span>
              </div>
            </div>
            <div
              v-if="detail.facebook"
              class="d-flex align-center mb-2"
            >
              <v-icon
                size="30px"
                class="mr-3"
              >
                {{ icons.mdiFacebook }}
              </v-icon>
              <div class="d-flex flex-column">
                <span class="text-caption text--disabled">Facebook</span>
                <span class="text-subtitle-2">{{ detail.facebook }}</span>
              </div>
            </div>
            <div
              v-if="detail.visibility"
              class="d-flex align-center mb-2"
            >
              <v-icon
                size="30px"
                class="mr-3"
              >
                {{ resolveIconVisibility(detail.visibility.id) }}
              </v-icon>
              <div class="d-flex flex-column">
                <span class="text-caption text--disabled">Visibilitas</span>
                <span class="text-subtitle-2">{{ detail.visibility.name }}</span>
              </div>
            </div>
          </div>
          <div v-if="detail.description">
            <v-divider />
            <div class="pa-4">
              <h5>Deskripsi Customer</h5>
              <span class="text-subtitle-2">
                {{ detail.description }}
              </span>
            </div>
          </div>
        </v-card>
        <v-card
          v-if="detail.type && detail.type.length"
          class="mt-6 pa-4 d-flex flex-column"
        >
          <span class="text-caption text--disabled">
            Tag Customer
          </span>
          <div class="d-flex flex-wrap mt-2">
            <v-chip
              v-for="tag in detail.type"
              :key="tag.id"
              :color="tag.color"
              small
              class="mr-1 mb-2"
            >
              {{ tag.name }}
            </v-chip>
          </div>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="8"
        lg="9"
      >
        <v-tabs
          v-model="currentTab"
          class="user-tabs mb-4"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.label"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span class="text-capitalize text-subtitle-2">{{ tab.label }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items
          id="user-tabs-content"
          v-model="currentTab"
          touchless
        >
          <v-tab-item>
            <customer-stats />
          </v-tab-item>
          <v-tab-item>
            <customer-prospect />
          </v-tab-item>
          <v-tab-item>
            <customer-job :customer-id="id" />
          </v-tab-item>
          <v-tab-item>
            <customer-task />
          </v-tab-item>
          <v-tab-item>
            <customer-logs />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <CustomerForm ref="customerForm" />
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import {
  mdiEmail, mdiMap, mdiBriefcase, mdiFacebook, mdiInstagram, mdiHistory,
  mdiChartAreaspline, mdiTagHeart, mdiTicketAccount, mdiCalendarCheck,
} from '@mdi/js'
import { avatarText } from '@core/utils/filter'
import Vue from 'vue'
import { apolloClient } from '@/vue-apollo'
import { customerDetail } from '@/graphql/queries'
import store from '@/store'
import AppBreadcrumb from '@/components/misc/AppBreadcrumb.vue'
import errorHandling from '@/utils/errorHandling'
import CustomerStats from './components/CustomerStats.vue'
import CustomerProspect from './components/CustomerProspect.vue'
// import CustomerJob from './components/CustomerJob.vue'
import CustomerJob from './components/CustomerJob.vue'
import CustomerTask from './components/CustomerTask.vue'
import CustomerLogs from './components/CustomerLogs.vue'
import useVisibility from '@/composables/useVisibility'
import router from '@/router'
import CustomerForm from '@/views/customer/CustomerForm.vue'
import { deleteCustomer } from '@/graphql/mutations'

export default {
  components: {
    AppBreadcrumb,
    CustomerStats,
    CustomerProspect,
    CustomerJob,
    CustomerTask,
    CustomerLogs,
    CustomerForm,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
      required: true,
    },
  },
  setup(props) {
    const detail = ref({})
    const loading = ref(false)
    const currentTab = ref(2)
    const tabs = ref([
      {
        label: 'Statistik',
        icon: mdiChartAreaspline,
      },
      {
        label: 'Prospek',
        icon: mdiTagHeart,
      },
      {
        label: 'Job',
        icon: mdiTicketAccount,
      },
      {
        label: 'Task',
        icon: mdiCalendarCheck,
      },
      {
        label: 'Riwayat',
        icon: mdiHistory,
      },
    ])

    const fetchDetail = () => {
      loading.value = true
      apolloClient
        .query({
          query: customerDetail,
          fetchPolicy: 'no-cache',
          variables: {
            customer_id: +props.id,
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        })
        .then(result => {
          detail.value = result.data.customerDetail
          loading.value = false
        }).catch(err => {
          loading.value = false
          router.push('/error')
          errorHandling(err)
        })
    }

    onMounted(() => {
      fetchDetail()
    })
    const deleteCustomerFunc = id => {
      Vue.$dialog({
        title: 'Hapus pelanggan?',
        body: 'Konfirmasi jika anda ingin menghapus pelanggan.',
      }).then(confirm => {
        if (confirm) {
          apolloClient.mutate({
            mutation: deleteCustomer,
            variables: {
              id,
              workspace_id: store.getters.getCurrentWorkspaceId,
            },
          }).then(() => {
            Vue.notify({
              title: 'Sukses',
              text: 'Berhasil menghapus pelanggan!',
            })
            router.push(`/${store.getters.getCurrentWorkspace.identifier_id}/customers`)
          }).catch(err => {
            errorHandling(err, 'Hapus Pelanggan')
          })
        }
      })
    }
    const { resolveIconVisibility } = useVisibility()

    return {
      loading,
      detail,
      currentTab,
      tabs,

      fetchDetail,
      avatarText,
      resolveIconVisibility,
      deleteCustomerFunc,

      icons: {
        mdiEmail,
        mdiMap,
        mdiBriefcase,
        mdiFacebook,
        mdiInstagram,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
