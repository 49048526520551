<template>
  <v-card :loading="loadingJob">
    <div class="pa-2">
      <v-text-field
        v-model="jobFilter.filter.search"
        placeholder="Cari job..."
        hide-details
        solo
        flat
        dense
        :prepend-inner-icon="icons.mdiMagnify"
        @input="debouncedFetchJobs"
      />
    </div>
    <v-divider />
    <div
      id="scrollList"
      v-scroll:#scrollList="listenScrollFetchMoreJob"
      class="scroll-list pa-3"
    >
      <v-row
        dense
        class="match-height"
      >
        <v-col
          v-for="job in jobList"
          :key="job.id"
          cols="12"
          md="6"
          lg="4"
          xl="3"
        >
          <job-card
            :data="job"
            @delete="deleteJob($event)"
            @refetch="fetchJobs"
          />
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import { onMounted } from '@vue/composition-api'
import { mdiMagnify } from '@mdi/js'
import useJob from '@/composables/useJob'
// import JobCard from '@/views/job/components/JobCard.vue'
import JobCard from '@/views/job/components/JobCard.vue'

export default {
  components: {
    JobCard,
  },
  props: {
    customerId: {
      type: [String, Number],
      default: null,
    },
  },
  setup() {
    const {
      jobList, loadingJob, jobFilter, loadingJobPagination,
      fetchJobs, listenScrollFetchMoreJob, deleteJob,
      debouncedFetchJobs,
    } = useJob()

    onMounted(() => {
      fetchJobs()
    })

    return {
      jobList,
      jobFilter,
      loadingJob,
      loadingJobPagination,
      fetchJobs,
      listenScrollFetchMoreJob,
      deleteJob,
      debouncedFetchJobs,

      icons: {
        mdiMagnify,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.scroll-list {
  @include style-scroll-bar();
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
