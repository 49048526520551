<template>
  <v-card
    outlined
    class="py-2 px-3 d-flex justify-space-between align-center"
  >
    <div class="d-flex flex-column">
      <span class="text-caption disabled--text">Judul Stats</span>
      <span class="text-h6 font-medium primary--text">1232</span>
    </div>
    <v-icon
      size="28"
    >
      {{ icons.mdiTicketAccount }}
    </v-icon>
  </v-card>
</template>

<script>
import { mdiTicketAccount } from '@mdi/js'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    stats: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      icons: {
        mdiTicketAccount,
      },
    }
  },
}
</script>

<style>

</style>
