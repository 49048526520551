<template>
  <v-card :loading="loadingTasks">
    <div class="pa-2">
      <v-text-field
        v-model="search"
        placeholder="Cari task..."
        hide-details
        solo
        flat
        dense
        :prepend-inner-icon="icons.mdiMagnify"
        @input="debouncedFetchTasks"
      />
    </div>
    <v-divider />
    <div
      id="taskList"
      v-scroll:#taskList="listenScrollFetchMore"
      class="scroll-list pt-2"
    >
      <TaskCard
        v-for="(task, index) in taskList"
        :key="`${task.id}${index}`"
        :task="task"
        @check="checkTask(task.id, task.completed_at != null)"
        @detail="$refs.taskDetail.show(task.id)"
        @delete="deleteTask"
      />
      <div
        class="d-flex my-2"
        style="min-height: 48px"
      >
        <v-progress-circular
          v-if="loadingFetchMore"
          indeterminate
          color="primary"
          class="mx-auto"
        />
      </div>
    </div>

    <TaskDetail
      ref="taskDetail"
      @refetch="fetchTasks"
    />
  </v-card>
</template>

<script>
import {
  ref, onMounted, computed,
} from '@vue/composition-api'
import { mdiMagnify } from '@mdi/js'
import useTask from '@/composables/useTask'
import TaskCard from '@/views/activity/task/TaskCard.vue'
import TaskDetail from '@/views/activity/task/TaskDetail.vue'

export default {
  components: {
    TaskCard,
    TaskDetail,
  },
  props: {
    customerId: {
      type: [String, Number],
      default: null,
    },
  },
  setup() {
    const search = ref('')

    const searchValue = computed(() => search.value)

    const {
      loadingTasks, taskList, fetchTasks, checkTask, loadingFetchMore,
      listenScrollFetchMore, deleteTask, debouncedFetchTasks,
    } = useTask({
      filter: ref({
        sort: 'end_date',
        order: 'DESC',
      }),
      pagination: ref({
        limit: 20,
        offset: 0,
      }),
      search: searchValue,
    })

    onMounted(() => {
      fetchTasks()
    })

    return {
      search,
      loadingTasks,
      taskList,
      loadingFetchMore,
      fetchTasks,
      checkTask,
      listenScrollFetchMore,
      deleteTask,
      debouncedFetchTasks,

      icons: {
        mdiMagnify,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.scroll-list {
  @include style-scroll-bar();
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
