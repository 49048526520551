import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import Scroll from 'vuetify/lib/directives/scroll';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"loading":_vm.loadingJob}},[_c('div',{staticClass:"pa-2"},[_c(VTextField,{attrs:{"placeholder":"Cari job...","hide-details":"","solo":"","flat":"","dense":"","prepend-inner-icon":_vm.icons.mdiMagnify},on:{"input":_vm.debouncedFetchJobs},model:{value:(_vm.jobFilter.filter.search),callback:function ($$v) {_vm.$set(_vm.jobFilter.filter, "search", $$v)},expression:"jobFilter.filter.search"}})],1),_c(VDivider),_c('div',{directives:[{def: Scroll,name:"scroll",rawName:"v-scroll:#scrollList",value:(_vm.listenScrollFetchMoreJob),expression:"listenScrollFetchMoreJob",arg:"#scrollList"}],staticClass:"scroll-list pa-3",attrs:{"id":"scrollList"}},[_c(VRow,{staticClass:"match-height",attrs:{"dense":""}},_vm._l((_vm.jobList),function(job){return _c(VCol,{key:job.id,attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('job-card',{attrs:{"data":job},on:{"delete":function($event){return _vm.deleteJob($event)},"refetch":_vm.fetchJobs}})],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }