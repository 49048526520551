<template>
  <v-card>
    <PerfectScrollbar
      :options="{useBothWheelAxes:true}"
      class="target-list pa-3 pb-0"
    >
      <customer-stats-card
        v-for="i in 5"
        :key="i"
        class="target-list-card"
      />
    </PerfectScrollbar>
    <div class="pa-3">
      <v-card
        outlined
        height="50vh"
        class="d-flex justify-center align-center"
      >
        Performance Goes Here
      </v-card>
    </div>
  </v-card>
</template>

<script>
import { mdiMagnify } from '@mdi/js'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import CustomerStatsCard from './CustomerStatsCard.vue'

export default {
  components: {
    CustomerStatsCard,
    PerfectScrollbar,
  },
  setup() {
    return {
      icons: {
        mdiMagnify,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';

.scroll-list {
  @include style-scroll-bar();
  max-height: 64vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.target-list {
  display: flex;
  overflow-x: auto;

  .target-list-card {
    flex-shrink: 0;
    width: 240px;
    margin-right: 12px;
  }
}
</style>
