import { render, staticRenderFns } from "./CustomerJob.vue?vue&type=template&id=955a6352&"
import script from "./CustomerJob.vue?vue&type=script&lang=js&"
export * from "./CustomerJob.vue?vue&type=script&lang=js&"
import style0 from "./CustomerJob.vue?vue&type=style&index=0&id=955a6352&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports